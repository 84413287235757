//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import { mapActions, mapGetters } from 'vuex';
import InlineTextEditor from '@/components/ui/InlineTextEditor.vue';

extend('regex', {
  ...regex,
  message: 'Invalid format',
});

export default {
  name: 'Settings',
  data() {
    return {
      loading: false,

      email: '',
      phone: '',
      oldPassword: '',
      password: '',
      passwordRepeat: '',

      token: '',
      changePasswordSuccess: false,

      changeEmailModal: false,
      changePhoneModal: false,
      changePasswordModal: false,
    };
  },
  components: {
    'inline-text-editor': InlineTextEditor,
    ValidationObserver,
    ValidationProvider,
  },
  async created() {
    await this.getProfile();
  },
  computed: {
    ...mapGetters('user', ['getProfileData']),
    profile() {
      return this.getProfileData;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile', 'updateUser']),
    ...mapActions('auth', ['confirmEmail']),
    async save(field) {
      this.loading = true;
      const data = {};
      if (field === 'email') {
        data[field] = this[field];
      } else if (field === 'phone') {
        data[field] = this[field];
      } else {
        data[field] = this.profile[field];
      }

      await this.updateUser(data)
        .then(() => {
          this.$bvModal.hide('change-email-modal');
          this.$bvModal.hide('change-phone-modal');
          this.phone = '';
          return this.getProfile();
        }).catch((error) => {
          if (error.response.data.error.name === 'validationerror') {
            const errors = {};
            error.response.data.errorDetails.forEach((item) => {
              errors[item.path] = item.message.charAt(0).toUpperCase() + item.message.slice(1); // capitalize error msg
            });
            this.$refs.observer.setErrors(errors);
          }
        }).finally(() => {
          this.loading = false;
        });
    },
    async cancelChange() {
      await this.updateUser({ email: this.profile.email });
      await this.getProfile();
    },
    async confirm() {
      await this.confirmEmail({ email: this.profile.pendingEmail, token: this.token });
      await this.getProfile();
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/[^\d+]/, '');
    },
    async changePassword() {
      const valid = await this.$refs.passwordobserver.validate();
      if (!valid) {
        return;
      }

      this.loading = true;
      await this.updateUser({ oldPassword: this.oldPassword, password: this.password })
        .then(() => {
          this.changePasswordSuccess = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hidePasswordModal() {
      this.oldPassword = '';
      this.password = '';
      this.passwordRepeat = '';
      this.changePasswordSuccess = false;
      this.$bvModal.hide('change-password-modal');
    },
  },
};
