//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SelectAction',
  props: ['category', 'loading'],
  components: {
  },
  data() {
    return {
      currentEAN: '',
      searchTypeRadio: 'ean',
      selectedCategory: 'lights',
      categories: [
        { label: 'lights', value: 'generalLights' },
        { label: 'sensors', value: 'generalSensors' },
        { label: 'switches', value: 'generalSwitches' },
      ],
    };
  },
  mounted() {
    if (this.categories !== 'all') {
      this.selectedCategory = this.category || 'lights';
    }
  },
  methods: {
    async handleSearch() {
      this.$emit('search', { ean: this.currentEAN, category: this.selectedCategory });
    },
    async handleFindDiff() {
      this.$emit('findDiff');
    },
  },
};
