//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import NavItem from '@/components/layouts/navbars/sidebar/NavItem.vue';
import { mapGetters } from 'vuex';

const icons = {
  document: [require('../../../../assets/icons/sidebar/project/documents.svg'), require('../../../../assets/icons/sidebar/project/documents_active.svg')],
  admin: [require('../../../../assets/icons/sidebar/project/coworkers.svg'), require('../../../../assets/icons/sidebar/project/coworkers_active.svg')],
};

export default {
  name: 'SettingsSidebar',
  components: { NavItem },
  data() {
    return {
      active: 'settings-legal',
    };
  },
  created() {
    this.active = this.$route.name;
    this.fallbackToEnabledView();
  },
  watch: {
    remoteControlEnabled() {
      this.fallbackToEnabledView();
    },
    routeName(value) {
      this.active = value;
    },
  },
  computed: {
    ...mapGetters('user', ['getProfileData']),
    items() {
      return [
        {
          title: this.$t('pageLegalDocuments'),
          to: { name: 'settings-legal' },
          icons: icons.document,
          hidden: false,
        },
        {
          title: this.$t('pageAdministrators'),
          to: { name: 'settings-admin' },
          icons: icons.admin,
          hidden: this.getProfileData?.role !== 'super_admin',
        },
      ].filter((i) => !i.hidden);
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    fallbackToEnabledView() {
      if (this.remoteControlEnabled) {
        const currentItem = this.items.find((i) => i.to.name === this.active);
        const firstEnabledItem = this.items.find((i) => !i.disabled);
        if (currentItem && currentItem.disabled) {
          this.onItemClick(firstEnabledItem);
        }
      }
    },
  },
};
