//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import ListItem from '@/components/ui/ListItem.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import { formatDate } from '@/utils/helpers';

export default {
  name: 'FirmwareModal',
  props: ['selectedFirmware', 'products', 'product', 'loading'],
  components: {
    ListItem,
    InputField,
    SectionTitle,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      internalFirmware: {},
      internalProduct: null,
      internalFirmwareTemplate: {
        name: '',
        productUuid: '',
        version: '',
        mandatory: false,
        testRelease: false,
        releaseNotes: '',
      },
      fileSizeLimit: 60,
      showFileError: false,
      showFileSizeError: false,
      firmwareFile: new File([''], ''),
    };
  },
  computed: {
    computedProducts() {
      return this.products.map((product) => {
        const newProduct = product;
        newProduct.displayName = `${product.name} (${product.model})`;
        return newProduct;
      });
    },
    modalTitle() {
      return this.selectedFirmware && this.selectedFirmware.name && this.selectedFirmware.name.length ? this.selectedFirmware.name : this.$t('firmwareNewFirmware');
    },
    fileError() {
      return !this.existingFirmware && this.showFileError && this.noFileSelected;
    },
    fileSizeError() {
      const fileSize = this.firmwareFile.size / 1024 / 1024;
      return !this.existingFirmware && fileSize > 0 && fileSize > this.fileSizeLimit;
    },
    noFileSelected() {
      return !this.existingFirmware && this.firmwareFile.name.length === 0;
    },
    noProductSelected() {
      return !this.existingFirmware && !this.internalProduct;
    },
    existingFirmware() {
      return this.selectedFirmware && this.selectedFirmware.name && this.selectedFirmware.name.length > 0;
    },
    selectedProduct() {
      return this.products.find((p) => p.model === this.selectedFirmware.identity.product.model) || {};
    },
    selectedProductId() {
      return this.selectedProduct.uuid || '';
    },
    selectedProductName() {
      return this.existingFirmware && this.selectedProduct && this.selectedProduct.name ? `${this.selectedProduct.name} <small>(${this.selectedProduct.model})</small>` || this.internalFirmware.model : '';
    },
  },
  methods: {
    async onSave() {
      this.showFileError = true;
      const valid = await this.$refs.observer.validate();
      if (!valid || this.noFileSelected || this.fileSizeError) {
        return;
      }
      if (this.noProductSelected) {
        this.$refs.productSelect.$refs.search.focus();
        return;
      }
      let data = {};
      if (!this.existingFirmware) {
        data = this.internalFirmware;
        data.firmware = this.firmwareFile;
        this.$emit('upload', data);
      } else {
        data.name = this.internalFirmware.name;
        data.releaseNotes = this.internalFirmware.releaseNotes;
        data.mandatory = this.internalFirmware.mandatory;
        data.testRelease = this.internalFirmware.testRelease;
        this.$emit('edit', data);
      }
    },
    onCancel() {
      this.$bvModal.hide('firmware-modal');
    },
    async onShow() {
      this.showFileError = false;
      this.firmwareFile = new File([''], '');
      this.internalProduct = JSON.parse(JSON.stringify(this.product));
      this.$nextTick(() => {
        this.internalFirmware = this.setInternalFirmware();
        if (!this.existingFirmware) {
          this.setProductUuid(this.internalProduct);
        }
      });
    },
    setInternalFirmware() {
      const fw = this.selectedFirmware;
      if (fw) {
        return {
          name: fw.name,
          version: fw.displayVersion,
          productUuid: fw.identity.productUUID,
          mandatory: fw.mandatory,
          testRelease: fw.testRelease,
          releaseNotes: fw.releaseNotes,
        };
      }
      return { ...this.internalFirmwareTemplate };
    },
    setProductUuid(product = this.internalProduct) {
      this.internalFirmware.productUuid = product.uuid;
    },
    getDate(value) {
      return formatDate(value);
    },
    humanFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / (1024 ** i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
  },
};
