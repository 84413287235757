//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import InputField from '@/components/ui/InputField.vue';
import IconLink from '@/components/ui/IconLink.vue';
import { getLocales, getLegalTypes } from '@/utils/helpers';

export default {
  name: 'LegalDocuments',
  components: {
    IconLink,
    InputField,
    SectionTitle,
    ValidationObserver,
  },
  data() {
    return {
      MAX_DATE: new Date(new Date().setHours(23, 59, 59, 999)),
      legalTypeTranslate: {
        imprint: 'pageImprint',
        tos: 'pageTermsOfUse',
        privacy: 'pagePrivacyPolicy',
        licenses: 'pageOpenSourceLicenses',
        troubleshooting: 'pageTroubleshootingGuide',
      },
      countryCode: '',
      loading: false,
      editing: false,
      contents: {},
      consentDateChanged: false,
      consentDateApproved: false,
    };
  },
  async created() {
    await this.getCountries();
    this.countryCode = this.selectedCountryCode;
    await this.getLegalContents();
    this.setLegalContents();
  },
  computed: {
    ...mapGetters('contents', ['getCountryCode', 'countriesLoaded', 'getLegalCountryList', 'getDefaultContents', 'getCountryContents', 'legalContentsLoaded']),
    locales() {
      return getLocales();
    },
    legalTypes() {
      return getLegalTypes();
    },
    countries() {
      return this.getLegalCountryList;
    },
    selectedCountryCode() {
      return this.getCountryCode;
    },
    hasCountriesLoaded() {
      return this.countriesLoaded;
    },
    isDefault() {
      return this.selectedCountryCode === 'default';
    },
    countryContents() {
      return this.getCountryContents;
    },
    defaultContents() {
      return this.getDefaultContents;
    },
    contentsLoaded() {
      return this.legalContentsLoaded;
    },
    showContents() {
      if (this.editing) {
        return this.hasCountriesLoaded;
      }
      return this.hasCountriesLoaded && !this.loading && this.contentsLoaded && this.countryCode && Object.keys(this.contents).length > 0;
    },
  },
  methods: {
    ...mapActions('contents', ['getCountries', 'changeCountry', 'getLegalContents', 'updateLegalContents']),
    async onChangeCountry(countryCode) {
      await this.changeCountry(countryCode);
      this.setLegalContents();
    },
    setLegalContents() {
      this.contents = JSON.parse(JSON.stringify(this.countryContents));
    },
    inputRules(code) {
      if (this.isDefault && code === 'en') {
        return 'required|max:255|url';
      }
      return 'max:255|url';
    },
    defaultBadgeLink(locale, type) {
      if (this.isDefault) {
        return '';
      }
      return this.defaultContents[locale] && !!this.defaultContents[locale][type].url ? this.defaultContents[locale][type].url : '';
    },
    async updateLegal() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      if (this.consentDateChanged && !this.consentDateApproved) {
        this.$bvModal.show('confirmation-consent-date-modal');
        return;
      }

      this.loading = true;
      await this.updateLegalContents(this.contents);
      await this.getLegalContents();
      this.loading = false;
      this.editing = false;
      this.consentDateChanged = false;
      this.consentDateApproved = false;
    },
    editLegal() {
      this.editing = true;
    },
    cancelEditLegal() {
      this.setLegalContents();
      this.editing = false;
      this.consentDateChanged = false;
      this.consentDateApproved = false;
    },
    onUpdatedAtChange(date, code, type) {
      const today = new Date().toISOString().split('T')[0];
      if (date === today) {
        this.contents[code][type].contentUpdatedAt = new Date().toISOString();
      }
      this.consentDateChanged = true;
    },
    onConfirmConsentDate() {
      this.consentDateApproved = true;
      this.$bvModal.hide('confirmation-consent-date-modal');
      this.updateLegal();
    },
    onCancelConsentDate() {
      this.consentDateApproved = false;
      this.$bvModal.hide('confirmation-consent-date-modal');
    },
  },
};
