//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import AdvancedTable from '../../components/ui/AdvancedTable.vue';
import AdminModal from '../../components/ui/admins/AdminModal.vue';

export default {
  name: 'Admins',
  components: {
    AdminModal,
    AdvancedTable,
  },
  data() {
    return {
      loading: false,
      selectedAdmin: null,
      headers: [{
        title: 'Email', key: 'email', type: 'text', searchable: true,
      }, {
        title: 'Name', key: 'name', type: 'text', searchable: true,
      }, {
        title: 'Country', key: 'countryName', type: 'text', searchable: true,
      }, {
        title: 'Phone', key: 'phone', type: 'text', searchable: true,
      }, {
        title: 'Role', key: 'roleName', type: 'text', searchable: true,
      }],
    };
  },
  async created() {
    await this.getAdmins();
    await this.getCountries();
  },
  computed: {
    ...mapState('admins', ['admins']),
    items() {
      return this.admins.map((admin) => ({
        uuid: admin.uuid,
        email: admin.email || '',
        name: admin.name || '',
        phone: admin.phone || '',
        countryId: admin.countryId,
        countryName: admin.country?.name ? `${admin.country.name} (${admin.country.businessRegionName})` : '',
        region: admin.country?.businessRegionName || this.$t('generalAll'),
        role: admin.role,
        roleName: this.$t(`role_${admin.role}`) || '',
      }));
    },
  },
  methods: {
    ...mapActions('admins', ['getAdmins', 'deleteAdmin']),
    ...mapActions('contents', ['getCountries']),
    openCreateModal() {
      if (this.loading) {
        return;
      }
      this.selectedAdmin = null;
      this.$refs.adminModal.show();
    },
    openEditModal(admin) {
      if (this.loading) {
        return;
      }
      this.selectedAdmin = admin;
      this.$refs.adminModal.show();
    },
    onDeleteAdmin(admin) {
      this.selectedAdmin = admin;
      this.$bvModal.show('confirmation-delete-admin-modal');
    },
    async deleteAdminUser() {
      this.loading = true;
      try {
        await this.deleteAdmin({ uuid: this.selectedAdmin.uuid });
        await this.getAdmins();
        this.onCancelModal();
      } finally {
        this.loading = false;
      }
    },
    onCancelModal() {
      this.$bvModal.hide('confirmation-delete-admin-modal');
      this.selectedAdmin = null;
    },
  },
};
