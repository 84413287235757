//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ConfirmationModal',
  props: ['loading', 'title', 'id', 'large'],
};
