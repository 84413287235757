//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'login',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      forgot: '',
      reminderSent: false,
    };
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('auth', ['login', 'forgotPassword']),
    async onLogin() {
      const { email, password, rememberMe } = this;
      await this.login({ email, password, rememberMe })
        .then(async () => {
          await this.getProfile();
          await this.$router.push({ name: 'projects' });
        });
    },
    async sendForgotPasswordMail() {
      const { forgot } = this;
      await this.forgotPassword(forgot).then(() => {
        this.reminderSent = true;
      });
    },
  },
  computed: {
    authState() {
      return this.$store.state.auth;
    },
  },
  created() {
    // force clear localStorage to avoid vuex errors
    localStorage.clear();
  },
  mounted() {
    if (this.$route.query.forgotpassword) {
      this.$bvModal.show('forgot-password-modal');
    }
  },
};
