//
//
//
//
//
//
//
//

export default {
  name: 'NavItem',
  props: ['title', 'icons', 'isActive', 'disabled', 'hasIssue', 'to'],
};
