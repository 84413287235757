//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import NavUserDropdown from '@/components/layouts/navbars/NavUserDropdown.vue';
import NavLangDropdown from '@/components/layouts/navbars/NavLangDropdown.vue';
import config from '../../../config';

export default {
  name: 'NavBarMain',
  components: {
    NavUserDropdown,
    NavLangDropdown,
  },
  data() {
    return {
      version: config.PACKAGE_VERSION,
      gitHash: config.GIT_HASH,
    };
  },

  computed: {
    ...mapState('app', ['appData', 'locale']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('user', ['getProfileData']),
    adminRole() {
      return this.getProfileData?.role;
    },
    backendVersion() {
      if (this.appData && this.appData.api) {
        return this.appData.api.version;
      }
      return '';
    },
  },
  methods: {
    navTo(to) {
      this.$bvModal.hide('nav-overlay');
      this.$router.push(to);
    },
  },
};
