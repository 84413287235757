//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['item', 'loading', 'title'],
  computed: {
    modalTitle() {
      return this.title ? this.title : this.$t('generalDelete');
    },
  },
};
