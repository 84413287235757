//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ListItem',
  props: ['label', 'text', 'link', 'oneRow', 'slotContainerClasses', 'inline'],
};
