//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bus from '@/bus';
import { Html5Qrcode } from 'html5-qrcode';
import { mapActions } from 'vuex';

const QR_ERROR_IMAGE = require('@/assets/icons/images/went-wrong.svg');

export default {
  name: 'QRAccessProjectModal',
  props: ['submitAccessCode'],
  data() {
    return {
      error: false,
      qrCodeImage: null,
      qrReadError: false,
      project: null,
      gateway: null,
    };
  },
  created() {
    this.resetModal();
    bus.$on('showQRAccessProjectModal', this.showModal);
  },
  beforeDestroy() {
    bus.$off('showQRAccessProjectModal', this.showModal);
  },
  computed: {
    title() {
      if (this.qrReadError) {
        return this.$t('projectAccessProjectQRFailed');
      }

      return this.$t('projectAccessProjectQR');
    },
    modalText() {
      if (this.qrReadError) {
        return this.$t('projectAccessQRFailedText');
      }

      return this.$t('projectAccessQRText');
    },
  },
  methods: {
    ...mapActions('projects', ['qrValidate']),
    async getQRCode(imageFile) {
      const readerElement = document.createElement('div');
      readerElement.id = 'reader';
      readerElement.style.display = 'none';
      document.body.appendChild(readerElement);
      const html5QrCode = new Html5Qrcode('reader');
      return html5QrCode.scanFile(imageFile, false);
    },
    uploadQRCode() {
      this.resetModal();
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';

      input.addEventListener('change', async () => {
        const file = input.files[0];
        try {
          const qrCodeMessage = await this.getQRCode(file);
          const { project, gateway } = await this.qrValidate(qrCodeMessage);
          const blob = new Blob([file]);
          this.qrCodeImage = URL.createObjectURL(blob);
          this.project = project;
          this.gateway = gateway;
        } catch (error) {
          console.error(error);
          this.qrReadError = true;
          this.qrCodeImage = QR_ERROR_IMAGE;
        } finally {
          input.remove();
        }
      });

      input.click();
    },
    showModal() {
      this.resetModal();
      this.$bvModal.show('qr-access-project-modal');
    },
    async onAccessProject() {
      await this.submitAccessCode(this.project.adminAccessCode);
      this.closeModal();
    },
    closeModal() {
      this.error = false;
      this.$bvModal.hide('qr-access-project-modal');
      this.resetModal();
    },
    resetModal() {
      this.error = false;
      this.qrCodeImage = null;
      this.qrReadError = false;
      this.project = null;
      this.gateway = null;
    },
  },
};
