//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';

export default {
  props: ['type', 'label', 'rules', 'value', 'rows', 'placeholder', 'readMode', 'options', 'editable',
    'simpleInputLabel', 'tooltip', 'schema', 'isLink', 'bottomBorder', 'badgeLabel', 'badgeLink', 'showBadge',
    'unit', 'errorMessage', 'infoMessage', 'highlighted', 'maxDate'],
  components: {
    ValidationProvider,
  },
  data() {
    return {
      internalValue: null,
      relationalOptions: ['<', '>', '≤', '≥', '='],
    };
  },
  watch: {
    value() {
      this.setInternalValue();
    },
  },
  computed: {
    computedRules() {
      let { rules } = this;
      if (this.type === 'integer') {
        rules += '|numeric';
      }
      if (this.type === 'number') {
        rules += '|double';
      }
      return rules;
    },
    textValue() {
      if (this.type === 'select') {
        const val = this.options.find((o) => o.value === this.value);
        if (!val) {
          return this.val;
        }
        return val.label || val.value;
      }
      if (this.type === 'date') {
        return this.value ? new Date(this.value).toISOString().substring(0, 10) : '';
      }
      return this.value || '';
    },
    formattedRange() {
      if (this.type !== 'range') {
        return '';
      }
      return this.internalValue.length ? this.internalValue.join('...') : null;
    },
    secondRangeError() {
      return !!this.internalValue[0] && !this.internalValue[1];
    },
  },
  created() {
    this.setInternalValue(this.value);
  },
  methods: {
    setInternalValue() {
      if (this.type === 'range') {
        this.internalValue = this.value ? this.value.split(/(\...)/).filter((item) => item !== '...') : [null, null];
        return;
      }

      if (this.type === 'relational') {
        this.internalValue = this.value ? [this.value.substring(0, 1), this.value.substring(1)] : [null, null];
        return;
      }

      if (this.type === 'date') {
        if (this.isValidDateValue(this.value)) {
          const date = new Date(this.value);
          this.internalValue = this.value ? date.toISOString().substring(0, 10) : '';
        }

        return;
      }

      this.internalValue = this.value;
    },
    formatInput() {
      if (this.schema === 'phone') {
        this.internalValue = this.internalValue.replace(/[^\d+]/, '');
      }
    },
    handleRange() {
      if (!this.internalValue.length) {
        return;
      }
      this.$emit('input', this.formattedRange);
    },
    handleOpenRange(type, val) {
      let t0 = this.internalValue[0];
      let t1 = this.internalValue.substring(1) || '0';
      if (type === 0) {
        t0 = val;
      } else {
        t1 = val || '0';
      }
      this.internalValue = t0 + t1;
      this.$emit('input', this.internalValue);
    },
    onDateChange(val) {
      if (this.isValidDateValue(val)) {
        this.$emit('input', val);
      }
    },
    isValidDateValue(val) {
      const date = new Date(val);
      // eslint-disable-next-line no-restricted-globals
      const isDate = date instanceof Date && !isNaN(date);
      if (!isDate) {
        return false;
      }

      const maxDate = new Date(this.maxDate);
      return !(maxDate && date > maxDate);
    },
  },
};
