//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AdvancedTable from '../../AdvancedTable.vue';

export default {
  name: 'PimcoreDiff',
  props: ['pimcoreImport', 'loading', 'product'],
  components: {
    AdvancedTable,
  },
  data() {
    return {
      headers: [{
        title: this.$t('product.name'), key: 'name', type: 'text', sortable: true,
      }, {
        title: this.$t('productDiffFrom'), key: 'from', type: 'text', sortable: false, class: 'text-right',
      }, {
        title: '', key: 'arrow', type: 'text', sortable: false,
      }, {
        title: this.$t('productDiffTo'), key: 'to', type: 'text', sortable: false, class: 'text-right',
      }],
    };
  },
  computed: {
    items() {
      if (!this.product) {
        const arr1 = this.pimcoreImport?.differences?.filter((k) => !['family', 'productProperties'].includes(k.key)) || [];
        const arr2 = this.pimcoreImport?.differences?.find((d) => d.key === 'productProperties')?.changes || [];
        return [...arr1, ...arr2].map((diff) => {
          const changesFrom = diff.state === 'added' ? this.$t('productDiffMissing') : `${diff.old}`;
          const changesTo = diff.new;
          return ({
            name: `${diff.key} (${diff.state})`,
            from: diff.state === 'deleted' ? `${diff.new}` : (changesFrom),
            arrow: '→',
            to: diff.state === 'deleted' ? this.$t('productDiffDeleted') : (changesTo),
          });
        });
      }
      if (this.pimcoreImport?.catalogProduct) {
        const productFromDb = this.pimcoreImport.catalogProduct;
        const productToSave = this.product;
        const res = Object.entries(productToSave).reduce((acc, [key, newValue]) => {
          if (key === 'productProperties') {
            const oldPPs = productFromDb.productProperties || [];
            const productPropDiff = newValue.reduce((innerAcc, { key: ppKey, value: ppNewValue }) => {
              const oldPP = oldPPs.find((pp) => pp.key === ppKey);
              console.log(ppKey, { ppKey, ppNewValue, oldPP });
              if (!oldPP && ppNewValue) {
                return [...innerAcc, {
                  name: ppKey,
                  from: '',
                  arrow: '→',
                  to: ppNewValue,
                }];
              }
              if ((!oldPP && !ppNewValue) || oldPP.value === ppNewValue) {
                return innerAcc;
              }
              return [...innerAcc, {
                name: ppKey,
                from: oldPP.value,
                arrow: '→',
                to: ppNewValue,
              }];
            }, []);
            return [...acc, ...productPropDiff];
          }

          if (productFromDb[key] === newValue) {
            return acc;
          }

          const diff = {
            name: key,
            from: productFromDb[key] || this.$t('productDiffMissing'),
            arrow: '→',
            to: newValue || this.$t('productDiffDeleted'),
          };
          return [...acc, diff];
        }, []);
        return res;
      }
      return [];
    },
  },
};
