//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBarMain from '@/components/layouts/navbars/NavBarMain.vue';
import NavBarLocal from '@/components/layouts/navbars/NavBarLocal.vue';

/*
Valid layout options:
  - breadcrumbs (array)
  - browserBack (bool)
  - showProjectFilter (bool)
 */

export default {
  name: 'MainSidebarLayout',
  props: ['options'],
  components: {
    NavBarMain,
    NavBarLocal,
  },
  computed: {
    contentHeightCSS() {
      const navBarMainHeight = '4.375rem';
      const navBarLocalHeight = '3rem';
      const remoteControlBarHeight = '3rem';
      const heights = ['100vh', navBarMainHeight, navBarLocalHeight];
      if (this.remoteControlEnabled) {
        heights.push(remoteControlBarHeight);
      }
      return { height: `calc(${heights.join(' - ')})` };
    },
  },
};
