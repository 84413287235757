//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapActions, mapState } from 'vuex';
import bus from '@/bus';

export default {
  name: 'AccessProjectModal',
  props: ['disabled', 'hasError'],
  data() {
    return {
      error: false,
      fields: [
        { id: 0, value: '' },
        { id: 1, value: '' },
        { id: 2, value: '' },
        { id: 3, value: '' },
        { id: 4, value: '' },
        { id: 5, value: '' },
        { id: 6, value: '' },
        { id: 7, value: '' },
      ],
    };
  },
  watch: {
    hasError(newValue) {
      this.error = newValue;
      // nextTick does not work somehow
      setTimeout(() => this.$refs['input-0'][0].focus(), 100);
    },
  },
  created() {
    bus.$on('showAccessProjectModal', this.showModal);
  },
  beforeDestroy() {
    bus.$off('showAccessProjectModal', this.showModal);
  },
  computed: {
    accessCode() {
      return this.fields.map((field) => field.value).join('').toUpperCase();
    },
  },
  methods: {
    showModal() {
      this.resetModal();
      this.$bvModal.show('access-project-modal');
      this.$nextTick(() => {
        setTimeout(() => this.$refs['input-0'][0].focus(), 100);
      });
    },
    handleInput(e, field) {
      const valueLength = e.target.value.trim().length;
      this.error = false;
      // on input delete
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (field.id === 0) {
          this.fields[field.id].value = '';
          return;
        }
        this.fields[field.id - 1].value = '';
        this.$refs[`input-${field.id - 1}`][0].focus();
        return;
      }
      // on pasting access code
      if (field.id === 0 && valueLength === 8) {
        const chars = [...e.target.value];
        this.fields = this.fields.map((f, i) => ({ ...f, value: chars[i] }));
        this.$refs['input-0'][0].blur();
        this.$emit('submit-access-code', this.accessCode);
        return;
      }
      // slicing first input value
      if (field.id === 0 && valueLength > 1) {
        this.fields[field.id].value = e.target.value.slice(-1);
      }
      // filtering out not allowed characters
      if (!/^[a-zA-Z0-9]+$/.test(e.target.value)) {
        this.fields[field.id].value = '';
        return;
      }
      // handling focus and emit
      if (field.id !== 7) {
        this.$refs[`input-${field.id + 1}`][0].focus();
      } else if (field.value.length > 0) {
        this.$refs['input-7'][0].blur();
        this.$emit('submit-access-code', this.accessCode);
      }
    },
    closeModal() {
      if (this.disabled) {
        return;
      }
      this.error = false;
      this.$bvModal.hide('access-project-modal');
      this.resetModal();
    },
    resetModal() {
      this.fields = this.fields.map((field) => ({ ...field, value: '' }));
    },
  },
};
