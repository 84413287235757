//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconLink from '@/components/ui/IconLink.vue';
import SearchField from '@/components/ui/SearchField.vue';
import CustomModal from '@/components/ui/CustomModal.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';

export default {
  components: {
    CustomModal,
    SearchField,
    IconLink,
    FixedFooter,
  },
  props: [
    'title', 'items', 'headers', 'showHeader', 'loading', 'readOnly',
    'hideRowEdit', 'hideRowDelete', 'hideRowArrow',
    'hideBulkDelete', 'hideCreate', 'hideSearch',
    'simpleTable', 'createIllustration',
    'renameCreate', 'createButtonOnTable', 'collapsable',
    'category', 'rooms', 'groups', 'disabledView', 'secondaryHeader',
    'savedSort', 'savedSearchText',
    'defaultSortingIndex', 'disableHover',
  ],
  data() {
    return {
      bulkEditMode: false,
      bulkDeleteModal: false,
      selectedIndexes: [],
      sort: null,
      sortType: null,
      searchText: '',
      expanded: true,
    };
  },
  created() {
    this.sort = (this.sortFields.find((f) => f.selected) || {}).sort;
    this.sortType = (this.sortFields.find((f) => f.selected) || {}).sortType;
    this.emitFilterChange();
  },
  computed: {
    footerButtons() {
      return {
        main: {
          title: this.renameCreate || this.$t('pageCreate'),
          click: this.onCreateItem,
        },
      };
    },
    bulkDeleteModalProps() {
      return {
        title: this.$t('generalBulkDeleteItems'),
        message: this.$t('generalBulkDeleteMessage'),
        buttons: {
          main: {
            title: this.$t('generalDelete'),
            click: () => {
              this.onBulkDelete();
            },
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.bulkDeleteModal = false;
            },
          },
        },
      };
    },
    searchableHeaders() {
      return this.headers
        .filter((h) => h.searchable);
    },
    sortableHeaders() {
      return this.headers
        .filter((h) => h.sortable);
    },
    sortFields() {
      const fields = [];
      this.sortableHeaders
        .forEach((h) => {
          fields.push({ sort: `${h.key}##asc`, text: `${h.title} ${this.$t('generalAsc')}`, sortType: h.type });
          fields.push({ sort: `${h.key}##desc`, text: `${h.title} ${this.$t('generalDesc')}`, sortType: h.type });
        });
      if (fields[0]) {
        if (this.defaultSortingIndex && this.defaultSortingIndex > 0) {
          fields[this.defaultSortingIndex].selected = true;
        } else {
          fields[0].selected = true;
        }
      }
      return fields;
    },
    filteredItems() {
      return this.items
        .filter((item) => !this.searchText || this.searchableHeaders.some((h) => this.toText(item[h.key]).toLowerCase().includes(this.searchText.toLowerCase())))
        .sort((a, b) => {
          if (!this.sort) {
            return 1;
          }
          const [key, direction] = this.sort.split('##');
          if (this.sortType === 'number') {
            return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
          }
          return (direction === 'asc' ? 1 : -1) * this.toText(a[key]).localeCompare(this.toText(b[key]));
        });
    },
  },
  methods: {
    toText(val) {
      return `${val || ''}`;
    },
    onFilterChange(e) {
      this.sort = e.sort;
      this.sortType = e.sortType;
      this.searchText = e.searchText;
      this.emitFilterChange();
    },
    emitFilterChange() {
      this.$emit('getSearchListLength', this.filteredItems.length);
      this.$emit('filterChange', {
        sort: this.sort,
        sortType: this.sortType,
        searchText: this.searchText,
      });
    },
    onRowSelect(item, i) {
      if (this.disabledView) {
        return;
      }
      if (!this.bulkEditMode) {
        this.$emit('openItem', item);
      } else {
        this.onSelectItem(i);
      }
    },
    onSelectItem(index) {
      if (this.selectedIndexes.findIndex((i) => i === index) !== -1) {
        this.selectedIndexes = this.selectedIndexes.filter((i) => i !== index);
      } else {
        this.selectedIndexes = [...this.selectedIndexes, index];
      }
    },
    onSelectAll() {
      if (this.selectedIndexes.length === this.items.length) {
        this.selectedIndexes = [];
      } else {
        this.selectedIndexes = this.items.map((item, i) => i);
      }
    },
    onBulkEditToggle() {
      this.bulkEditMode = !this.bulkEditMode;
      this.selectedIndexes = [];
      this.$emit('editMode', this.bulkEditMode ? this.category : '');
    },
    onBulkDelete() {
      this.$emit('bulkDelete', this.selectedIndexes.map((i) => this.filteredItems[i]));
      this.bulkDeleteModal = false;
      this.onBulkEditToggle();
    },
    onCreateItem() {
      this.$emit('createItem');
    },
  },
};
