//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import IconLink from '@/components/ui/IconLink.vue';
import ConfirmationModal from '@/components/ui/ConfirmationModal.vue';
import ProductEditor from '@/components/ui/products/ProductEditor.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default {
  name: 'ProductDetails',
  components: {
    Spinner,
    ProductEditor,
    FixedFooter,
    IconLink,
    ConfirmationModal,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      loadingImages: false,
      editing: false,
      editingImages: false,
      editingImagesDelete: false,
      deletingProduct: false,
      currentProduct: {},
      currentProductProperties: [],
      showFileError: false,
      showFileSizeError: false,
      selectedIndex: 0,
      images: [],
      selectedAttachmentUUIDs: [],
    };
  },
  async created() {
    await this.getProduct(this.productId);

    if (!this.productProperties?.length) {
      await this.getProperties();
    }

    await Promise.all([
      this.listFamiliesMeta(),
      this.getCountries(),
    ]);
    await this.setProduct();
    this.loaded = true;
  },
  computed: {
    ...mapState('app', ['locale']),
    ...mapState('firmwares', ['radioFilters']),
    ...mapState('products', ['requestSent']),
    ...mapGetters('products', ['getProductById', 'getProductProperties', 'getFamiliesMeta']),
    ...mapGetters('contents', ['getCountryList']),
    product() {
      return this.getProductById(this.productId);
    },
    productId() {
      return this.$route.params.productId;
    },
    currentCategory() {
      return this.currentProduct.category;
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('productGoToFirmware'),
          class: this.loading ? 'disabled' : '',
          click: async () => {
            this.radioFilters.product = this.product.model;
            this.radioFilters.selected = this.product.category;
            await this.$router.push({ name: 'products-firmware' });
          },
        },
      };
    },
    productProperties() {
      return this.getProductProperties;
    },
    productFamilies() {
      return this.getFamiliesMeta;
    },
    pictures() {
      return (this.product.attachments && this.product.attachments.pictures) || [];
    },
    hasMorePictures() {
      return this.pictures.length > 1;
    },
    enableImageSave() {
      return this.selectedAttachmentUUIDs.length > 0 || this.images.length > 0;
    },
    imageModalTitle() {
      return this.editingImagesDelete
        ? this.$tc('productDeleteImage', this.selectedAttachmentUUIDs.length || 1, { value: this.selectedAttachmentUUIDs.length })
        : this.$tc('productUploadImage', this.images.length || 1, { value: this.images.length });
    },
    countries() {
      return this.getCountryList;
    },
  },
  methods: {
    ...mapActions('products', ['deleteProduct', 'uploadProductImages', 'deleteProductImages', 'listProducts', 'updateProduct', 'listFamiliesMeta', 'getProduct', 'getProperties', 'setAsDefaultAttachment']),
    ...mapActions('contents', ['getCountries']),
    selectedUUID(uuid) {
      return this.selectedAttachmentUUIDs.includes(uuid);
    },
    onImageClick(image, index, attachmentUUID) {
      if (this.editingImages) {
        if (this.selectedUUID(attachmentUUID)) {
          this.selectedAttachmentUUIDs = this.selectedAttachmentUUIDs.filter((uuid) => uuid !== attachmentUUID);
          return;
        }
        this.selectedAttachmentUUIDs.push(attachmentUUID);
        return;
      }
      if (image === 'prev') {
        if (this.selectedIndex === 0) {
          this.selectedIndex = this.pictures.length - 1;
          return;
        }
        this.selectedIndex -= 1;
        return;
      }
      if (image === 'next') {
        if (this.pictures.length === this.selectedIndex + 1) {
          this.selectedIndex = 0;
          return;
        }
        this.selectedIndex += 1;
        return;
      }
      this.selectedIndex = index;
    },
    async setProduct() {
      this.currentProduct = {
        ...this.product,
        ean: this.product.ean || '',
        family: this.product.family || '',
      };
      // todo: fix me please <3 **cute_puppy_face**
      const productProperties = [...new Set(this.productProperties)];
      this.currentProductProperties = productProperties.reduce((arr, p) => {
        let val = Object.entries(this.currentProduct.productProperties)
          // eslint-disable-next-line
          .map(([key, value]) => value)
          .filter((prod) => prod.key === p.key);
        val = (val[0] && val[0].value) || '';
        arr.push({ ...p, value: val });
        return arr;
      }, []);
    },
    editProduct() {
      this.editing = true;
    },
    editImages() {
      this.editingImages = true;
    },
    deleteImages() {
      this.editingImages = true;
      this.editingImagesDelete = true;
    },
    handleDeleteProduct() {
      this.deletingProduct = true;
      this.$bvModal.show('confirmation-modal');
    },
    async submitUpdateProduct() {
      this.deletingProduct = false;
      const valid = await this.$refs.productEditor?.observer?.validate();
      if (!valid) {
        return;
      }
      this.$bvModal.show('confirmation-modal');
    },
    async onConfirm() {
      if (this.deletingProduct) {
        await this.onDeleteProduct();
        return;
      }
      await this.onUpdateProduct();
    },
    cancelEditProduct() {
      this.setProduct();
      this.editing = false;
    },
    async onDeleteProduct() {
      this.loading = true;
      await this.deleteProduct(this.currentProduct.uuid)
        .then(() => this.listProducts())
        .then(() => {
          this.loading = false;
          this.deletingProduct = false;
          this.$bvModal.hide('confirmation-modal');
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
          this.deletingProduct = false;
          this.$bvModal.hide('confirmation-modal');
        });
    },
    async onUpdateProduct() {
      this.loading = true;
      const form = this.$refs.productEditor.getProduct();
      await this.updateProduct({ productUUID: this.currentProduct.uuid, data: form })
        .then(() => this.getProduct(this.currentProduct.uuid))
        .then(() => this.setProduct())
        .finally(() => {
          this.$bvModal.hide('confirmation-modal');
          this.loading = false;
          this.editing = false;
        });
    },
    // todo: temporary disabled numberOfChannels
    isNumberOfChannels(key) {
      return key === 'numberOfChannels';
    },
    async updateImages() {
      this.loadingImages = true;
      if (this.editingImagesDelete) {
        await this.deleteProductImages({ productUUID: this.productId, attachmentUUIDs: this.selectedAttachmentUUIDs });
      } else {
        await this.uploadProductImages({ productUUID: this.productId, images: this.images });
      }
      await this.listProducts();
      await this.setProduct();
      this.$bvModal.hide('confirmation-image-modal');
      this.cancelEditImages();
      this.loadingImages = false;
    },
    cancelEditImages() {
      this.editingImages = false;
      this.editingImagesDelete = false;
      this.selectedAttachmentUUIDs = [];
      this.images = [];
    },
    async setAsDefaultImage() {
      this.loading = true;
      const attachmentUUID = this.pictures[this.selectedIndex]?.attachmentUUID;
      if (!attachmentUUID) {
        return;
      }
      await this.setAsDefaultAttachment({ attachmentUUID })
        .then(() => this.getProduct(this.productId))
        .then(() => this.setProduct())
        .then(() => {
          this.selectedIndex = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

