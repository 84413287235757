//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import SelectAction from './PimcoreModalComponents/SelectAction.vue';
import ProductEditor from './ProductEditor.vue';
import ConfirmationModal from '../ConfirmationModal.vue';
import AdvancedTable from '../AdvancedTable.vue';
import PimcoreDiff from './PimcoreModalComponents/PimcoreDiff.vue';

const VIEW_STATES = {
  SELECT_ACTION: 'SELECT_ACTION',
  IMPORT_PRODUCT: 'IMPORT_PRODUCT',
  SHOW_DIFFERENCES: 'SHOW_DIFFERENCES',
};

export default {
  name: 'PimcoreModal',
  props: ['selectedCategory'],
  components: {
    PimcoreDiff,
    AdvancedTable,
    ConfirmationModal,
    ProductEditor,
    SelectAction,
  },
  data() {
    return {
      VIEW_STATES,
      loading: false,
      viewState: VIEW_STATES.SELECT_ACTION,
      viewStateStack: [VIEW_STATES.SELECT_ACTION],

      currentProduct: null,
      updateExisting: false,
      productToSave: null,

      headers: [{
        title: this.$t('product.name'), key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('product.ean'), key: 'ean', type: 'text', sortable: false, searchable: true,
      }, {
        title: this.$t('product.vendor'), key: 'vendor', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('productModel'), key: 'model', type: 'text', sortable: false, searchable: false,
      }],
      diffList: [],
    };
  },
  computed: {
    ...mapGetters('products', ['getPimcoreProductByEan', 'getProductProperties', 'getPimcoreImportDataByEan']),
    ...mapGetters('contents', ['getCountryList']),
    pimcoreProduct() {
      return this.getPimcoreProductByEan(this.currentEAN);
    },
    pimcoreImport() {
      return this.getPimcoreImportDataByEan(this.currentEAN);
    },
    productProperties() {
      return this.getProductProperties;
    },
    differencesProductList() {
      return this.diffList.filter((item) => item.differences.length).map((item) => item.catalogProduct);
    },
    countries() {
      return this.getCountryList;
    },
  },
  methods: {
    ...mapActions('products', ['importProductFromPimcore', 'listProducts', 'uploadProductImages', 'getProductFromPimcore', 'findDifferencesFromPimcore']),
    ...mapActions('contents', ['getCountries']),
    async openDiffProduct(product) {
      await this.handleSearch(product);
    },
    async handleSearch({ ean, category }) {
      this.currentEAN = ean;
      this.loading = true;
      await this.getProductFromPimcore({ ean, category }).finally(() => {
        this.loading = false;
      });
      if (!this.pimcoreProduct) {
        return;
      }
      this.currentProduct = cloneDeep(this.pimcoreProduct);
      this.viewState = VIEW_STATES.IMPORT_PRODUCT;
      this.viewStateStack.push(this.viewState);
    },
    async handleFindDiff() {
      this.loading = true;
      await this.findDifferencesFromPimcore().then((result) => {
        this.diffList = result;
        this.viewState = this.VIEW_STATES.SHOW_DIFFERENCES;
        this.viewStateStack.push(this.viewState);
      }).finally(() => {
        this.loading = false;
      });
    },
    async onSubmitProduct() {
      const isValid = await this.$refs.productEditor?.validate();
      if (!isValid) {
        return;
      }
      this.updateExisting = this.$refs?.productEditor?.productExistsInDatabase;
      this.productToSave = this.$refs.productEditor.getProduct();
      this.$bvModal.show('pimcore-confirm');
    },
    async onSaveProduct() {
      this.$bvModal.hide('pimcore-confirm');
      this.loading = true;
      const product = this.$refs.productEditor.getProduct();
      const images = this.$refs.productEditor.getImages();
      const { uuid } = await this.importProductFromPimcore({ product, updateExisting: this.updateExisting }).catch((error) => {
        this.loading = false;
        throw error;
      });
      if (images.length) {
        await this.uploadProductImages({ productUUID: uuid, images }).catch((error) => {
          this.loading = false;
          throw error;
        });
      }
      await this.listProducts();
      this.loading = false;
      this.$bvModal.hide('pimcore-modal');
      this.resetModal();
    },
    onCancel() {
      this.resetModal();
      this.$bvModal.hide('pimcore-modal');
    },
    resetModal() {
      this.currentProduct = null;
      this.viewState = VIEW_STATES.SELECT_ACTION;
      this.viewStateStack = [];
      this.viewStateStack.push(this.viewState);
    },
    onBack() {
      this.viewStateStack.pop();
      // eslint-disable-next-line prefer-destructuring
      this.viewState = this.viewStateStack.slice(-1)[0];
      if (this.viewState === VIEW_STATES.SELECT_ACTION) {
        this.resetModal();
      }
    },
  },
};
