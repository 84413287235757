//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import NavItem from '@/components/layouts/navbars/sidebar/NavItem.vue';

const icons = {
  devices: [require('@/assets/icons/sidebar/room/devices.svg'), require('@/assets/icons/sidebar/room/devices_active.svg')],
  gateway: [require('../../../../assets/icons/sidebar/project/gateway.svg'), require('../../../../assets/icons/sidebar/project/gateway_active.svg')],
  family: [require('../../../../assets/icons/sidebar/project/rooms.svg'), require('../../../../assets/icons/sidebar/project/rooms_active.svg')],
};

export default {
  name: 'ProductsSidebar',
  components: { NavItem },
  data() {
    return {
      active: 'products-firmware',
    };
  },
  created() {
    this.active = this.routeName;
    this.fallbackToEnabledView();
  },
  watch: {
    remoteControlEnabled() {
      this.fallbackToEnabledView();
    },
    routeName(value) {
      this.active = value;
    },
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('pageFirmware'),
          to: { name: 'products-firmware' },
          icons: icons.gateway,
        },
        {
          title: this.$t('pageCatalog'),
          to: { name: 'products-catalog' },
          icons: icons.devices,
        },
        {
          title: this.$t('pageFamilyMeta'),
          to: { name: 'products-families' },
          icons: icons.family,
        },
      ];
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    fallbackToEnabledView() {
      if (this.remoteControlEnabled) {
        const currentItem = this.items.find((i) => i.to.name === this.active);
        const firstEnabledItem = this.items.find((i) => !i.disabled);
        if (currentItem && currentItem.disabled) {
          this.onItemClick(firstEnabledItem);
        }
      }
    },
  },
};
