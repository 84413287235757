//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import { extend } from 'vee-validate';
import {
  confirmed, email, max, min, numeric, required, double,
} from 'vee-validate/dist/rules';
import PhoneNumber from 'awesome-phonenumber';
import ErrorMessage from '@/components/ErrorMessage.vue';
import NotificationToaster from '@/components/NotificationToaster.vue';

import bus from './bus';

export default {
  components: { NotificationToaster, ErrorMessage },
  data() {
    return {
      rerenderKey: 1,
    };
  },
  async created() {
    this.getAppData().catch(console.error);

    // set locale to previously saved locale
    this.changeLocale();

    // vee-validate extensions
    const phone = {
      validate(value) {
        const isValid = new Promise((resolve) => {
          const number = new PhoneNumber(value);
          resolve({ valid: number.isValid() });
        });
        const allowedChars = /^(\+?)(\d+)$/.test(value);
        if (!allowedChars) {
          return false;
        }
        return isValid;
      },
    };
    const password = {
      validate(value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(value);
      },
    };
    const version = {
      validate(value) {
        return /^\d+\.\d+\.\d+(\.\d+)?$/.test(value) || /^[\da-fA-F]{1,2}([\dA-Fa-f]{2})*$/.test(value);
      },
    };
    const url = {
      validate(value) {
        // eslint-disable-next-line
        return /^https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value);
      },
    };
    const hexString = {
      validate(value) {
        return /(^0x[\da-f]{2,4}$)|(^[\da-f]{2,4}$)/gi.test(value);
      },
    };
    extend('required', { ...required, message: () => this.$t('errorMessageRequired') });
    extend('min', { ...min, message: (value, params) => this.$t('errorMessageMinLength', { value: params.length }) });
    extend('max', { ...max, message: (value, params) => this.$t('errorMessageMaxLength', { value: params.length }) });
    extend('email', { ...email, message: () => this.$t('errorMessageEmail') });
    extend('phone', { ...phone, message: () => this.$t('errorMessagePhone') });
    extend('password', { ...password, message: () => this.$t('errorMessagePassword') });
    extend('confirmed', { ...confirmed, message: () => this.$t('errorMessageConfirmed') });
    extend('version', { ...version, message: () => this.$t('errorMessageVersion') });
    extend('url', { ...url, message: () => this.$t('errorMessageUrl') });
    extend('numeric', { ...numeric, message: () => this.$t('errorMessageNumeric') });
    extend('double', { ...double, message: () => this.$t('errorMessageNumeric') });
    extend('hexString', { ...hexString, message: () => this.$t('errorMessageHexString') });

    bus.$on('forceRerender', this.forceRerender.bind(this));
    window.addEventListener('beforeunload', this.beforeWindowUnload);
    if (this.isAuthenticated) {
      await this.getProfile();
    }
  },
  watch: {
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    ...mapGetters('auth', ['isAuthenticated']),
    projectUUID() {
      return this.$route.params && this.$route.params.projectId;
    },
    layout() {
      let layout = { component: 'div', options: {} };
      if (this.$route.meta.layout) {
        if (this.$route.meta.layout.component && this.$route.meta.layout.options) {
          layout = this.$route.meta.layout;
        } else if (this.$route.meta.layout.component) {
          layout = { component: this.$route.meta.layout.component, options: {} };
        } else {
          layout = { component: this.$route.meta.layout, options: {} };
        }
      }
      return layout;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('app', ['getAppData']),
    ...mapActions('app', ['changeLocale', 'getAppData', 'projectUpdatedEvent']),
    async beforeWindowUnload() {
      if (this.$route.params && this.$route.params.projectId) {
        // await this.unlockProject(this.$route.params.projectId);
      }
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
  },
};
