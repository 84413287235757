//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from 'vuex';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import FirmwareModal from '@/components/ui/products/FirmwareModal.vue';
import { formatDate } from '@/utils/helpers';
import DeleteModal from '@/components/ui/DeleteModal.vue';

export default {
  name: 'Firmware',
  components: {
    DeleteModal,
    FirmwareModal,
    FixedFooter,
    AdvancedTable,
  },
  data() {
    return {
      categories: ['gateways', 'lights', 'switches', 'sensors'],
      categoryTranslate: {
        gateways: 'generalControllers',
        lights: 'generalLights',
        sensors: 'generalSensors',
        switches: 'generalSwitches',
      },
      headers: [{
        title: this.$t('generalDate'), key: 'released', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('generalName'), key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('firmwareVersion'), key: 'displayVersion', type: 'text', sortable: false, searchable: true,
      }, {
        title: this.$t('firmwareMandatory'), key: 'mandatory', type: 'text', sortable: false, searchable: false, class: 'text-center',
      }, {
        title: this.$t('firmwareTest'), key: 'testRelease', type: 'text', sortable: false, searchable: false, class: 'text-center',
      }],
      selectedFirmware: null,
      loading: false,
      searchLength: 1,
    };
  },
  async created() {
    await this.listProducts();
    const firmware = await this.firmwares.find((fw) => fw.uuid === this.$route.query.uuid);
    if (this.$route.query.uuid && firmware) {
      this.selectedFirmware = firmware;
      await this.onOpenFirmware(this.selectedFirmware);
    }
  },
  computed: {
    ...mapState('firmwares', ['radioFilters', 'savedSort']),
    ...mapGetters('firmwares', ['getFirmwares']),
    ...mapGetters('products', ['productsLoaded', 'getProductsByCategory']),
    firmwareFilters() {
      return this.radioFilters;
    },
    firmwares() {
      return this.getFirmwares;
    },
    filteredFirmwares() {
      let items = this.firmwares;
      if (this.radioFilters.product) {
        items = this.firmwares.filter((fw) => fw.identity.product.model === this.radioFilters.product.model);
      }
      return items.map((fw) => ({ ...fw, to: { name: 'products-firmware', query: { uuid: fw.uuid } } }));
    },
    noFirmwares() {
      return this.filteredFirmwares.length === 0;
    },
    products() {
      return this.getProductsByCategory(this.radioFilters.selected).map((p) => ({ ...p, displayName: `${p.name} (${p.model})` }));
    },
    hasSelectedProduct() {
      return this.radioFilters.product;
    },
    deleteItemName() {
      if (this.selectedFirmware && this.selectedFirmware.name) {
        if (this.selectedFirmware.displayVersion) {
          return `${this.selectedFirmware.name} ${this.selectedFirmware.displayVersion}`;
        }
        return this.selectedFirmware.name;
      }
      return '';
    },
    selectedProduct() {
      return this.radioFilters.product || null;
    },
    selectedProductId() {
      return this.selectedProduct?.uuid || '';
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('firmwareNewFirmware'),
          class: this.loading ? 'disabled' : '',
          click: () => this.onOpenFirmware(),
        },
      };
    },
    selectedCategory() {
      return this.radioFilters.selected === 'gateways' ? 'lights' : this.radioFilters.selected;
    },
    computedSavedSort() {
      return this.savedSort;
    },
  },
  methods: {
    ...mapActions('firmwares', ['radioFiltersUpdate', 'listFirmwares', 'uploadFirmware', 'editFirmware', 'deleteFirmware', 'savedSortUpdate']),
    ...mapActions('products', ['listProducts']),
    onRadioFilterChange() {
      this.radioFilters.product = null;
      this.radioFiltersUpdate(this.firmwareFilters);
      this.$route.query.uuid = null;
      this.resetSavedSort();
    },
    onFilterChange(sort) {
      this.savedSortUpdate(sort);
    },
    getDate(value) {
      return formatDate(value);
    },
    async onOpenFirmware(item = null) {
      this.selectedFirmware = item;
      this.$nextTick(() => {
        this.$bvModal.show('firmware-modal');
        this.$route.query.uuid = item.uuid;
      });
    },
    async onProductSelectInput() {
      this.radioFiltersUpdate(this.firmwareFilters);
      await this.onListFirmwares();
      this.$route.query.uuid = null;
      this.resetSavedSort();
    },
    async onListFirmwares() {
      if (this.selectedProductId.length === 0) {
        return;
      }
      this.loading = true;
      await this.listFirmwares({ productUUID: this.selectedProductId })
        .finally(() => {
          this.loading = false;
        });
      this.$bvModal.hide('firmware-modal');
    },
    async onUploadFirmware(data) {
      this.loading = true;
      await this.uploadFirmware(data)
        .finally(() => {
          this.loading = false;
        });
      await this.onListFirmwares();
    },
    async onEditFirmware(data) {
      this.loading = true;
      await this.editFirmware({ uuid: this.selectedFirmware.uuid, data })
        .finally(() => {
          this.loading = false;
        });
      await this.onListFirmwares();
    },
    async onDeleteFirmware() {
      this.loading = true;
      await this.deleteFirmware({ uuid: this.selectedFirmware.uuid })
        .finally(() => {
          this.loading = false;
        });
      await this.onListFirmwares();
      this.$bvModal.hide('delete-modal');
    },
    getSearchListLength(length) {
      this.searchLength = length;
    },
    resetSavedSort() {
      this.savedSortUpdate({ sort: 'released##desc', sortType: 'text', searchText: '' });
    },
  },
};
