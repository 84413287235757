//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ConfirmationModal from '@/components/ui/ConfirmationModal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductEditor from './ProductEditor.vue';

export default {
  name: 'ProductModal',
  components: {
    ProductEditor,
    ConfirmationModal,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('app', ['locale']),
    ...mapState('products', ['radioFilters', 'eanCodes']),
    ...mapGetters('products', ['getProductProperties', 'getFamiliesMeta']),
    ...mapGetters('contents', ['getCountryList']),
    selectedCategory() {
      return this.radioFilters.selected;
    },
    countries() {
      return this.getCountryList;
    },
    productProperties() {
      return this.getProductProperties;
    },
    productFamilies() {
      return this.getFamiliesMeta;
    },
  },
  methods: {
    ...mapActions('products', ['createProduct', 'listProducts', 'uploadProductImages']),
    ...mapActions('contents', ['getCountries']),
    onCancel() {
      this.$bvModal.hide('new-product-modal');
    },
    async onSubmit() {
      const isValid = await this.$refs.productEditor?.validate();
      if (!isValid) {
        return;
      }
      this.$bvModal.show('confirmation-modal');
    },
    async onSaveProduct() {
      this.$bvModal.hide('confirmation-modal');
      this.loading = true;
      const product = this.$refs.productEditor.getProduct();
      const images = this.$refs.productEditor.getImages();
      const { uuid } = await this.createProduct(product).catch((error) => {
        this.loading = false;
        throw error;
      });
      if (images.length) {
        await this.uploadProductImages({ productUUID: uuid, images }).catch((error) => {
          this.loading = false;
          throw error;
        });
      }
      await this.listProducts();
      this.loading = false;
      this.$bvModal.hide('new-product-modal');
    },
    // todo: temporary disabled numberOfChannels
    isNumberOfChannels(key) {
      return key === 'numberOfChannels';
    },
  },
};

