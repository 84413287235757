//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FixedFooter',
  props: ['buttons', 'progressValue', 'progressMax'],
  methods: {
    onMainClick() {
      (this.buttons.main.click || (() => {}))();
    },
    onSecondaryClick() {
      (this.buttons.secondary.click || (() => {}))();
    },
    onTertiaryClick() {
      (this.buttons.tertiary.click || (() => {}))();
    },
  },
};
