//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import { mapActions } from 'vuex';

extend('regex', {
  ...regex,
  message: 'Invalid format',
});

export default {
  name: 'reset-password',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      passwordChanged: false,
      email: '',
      token: '',
      password: '',
      submitted: false,
      error: false,
    };
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    async updatePassword() {
      this.submitted = true;
      const { email, password, token } = this;
      await this.resetPassword({ email, password, token }).then(() => {
        this.passwordChanged = true;
        this.submitted = false;
        this.$router.push({ name: 'login' });
      }).catch((err) => {
        const error = err.response;
        if (error.status === 400) {
          this.error = true;
          this.submitted = false;
        }
      });
    },
  },
  created() {
    if (this.$route.query.email && this.$route.query.token) {
      this.email = this.$route.query.email;
      this.token = this.$route.query.token;
    }
  },
};
