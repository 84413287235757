//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';

export default {
  name: 'AdminEditor',
  components: {
    InputField,
    ValidationObserver,
  },
  props: ['readMode', 'admin', 'countries', 'authedAdmin'],
  data() {
    return {
      currentAdmin: {},
      roles: [
        { value: 'readonly', label: this.$t('role_readonly') },
        { value: 'support', label: this.$t('role_support') },
        { value: 'admin', label: this.$t('role_admin') },
        { value: 'super_admin', label: this.$t('role_super_admin') },
      ],
    };
  },
  computed: {
    observer() {
      return this.$refs.observer;
    },
    editMode() {
      return !!this.admin;
    },
    countryOptions() {
      const countries = [...this.countries];
      countries.sort((a, b) => a.businessRegionName.localeCompare(b.businessRegionName) || a.name.localeCompare(b.name));
      return countries.map((country) => ({ label: `${country.name} (${country.businessRegionName})`, value: country.id }));
    },
  },
  watch: {
    admin: {
      handler() {
        this.setLocalStates();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async validate() {
      const isValid = await this.observer?.validate();
      return isValid;
    },
    async setLocalStates() {
      if (!this.currentCategory) {
        this.currentCategory = this.category;
      }
      const admin = { ...(this.admin || {}) };
      this.currentAdmin = {
        ...admin,
      };
    },
    getAdmin() {
      return {
        name: this.currentAdmin.name || undefined,
        phone: this.currentAdmin.phone || undefined,
        email: this.currentAdmin.email || undefined,
        password: this.currentAdmin.password || undefined,
        countryId: this.currentAdmin.countryId || undefined,
        role: this.currentAdmin.role || undefined,
      };
    },
    onCountryChange({ value }) {
      this.currentAdmin.countryId = value;
    },
    onRoleChange({ value }) {
      this.currentAdmin.role = value;
    },
  },
};

