//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavLangDropdown from '@/components/layouts/navbars/NavLangDropdown.vue';

export default {
  name: 'NavBarMain',
  components: {
    NavLangDropdown,
  },
};
