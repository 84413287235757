//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from '../SectionTitle.vue';
import InputField from '../InputField.vue';

export default {
  name: 'ProductCountrySelector',
  components: { SectionTitle, InputField },
  props: ['countries', 'product', 'readMode', 'value'],
  data() {
    return {
      selectedCountries: {},
    };
  },
  watch: {
    product: {
      handler() {
        const selectedCountries = {};
        (this.product?.countries || []).forEach((country) => {
          selectedCountries[country.countryCode] = country.status === 'active';
        });
        this.selectedCountries = selectedCountries;
        this.onChange();
      },
      immediate: true,
    },
  },
  computed: {
    validCountryCodes() {
      return this.countries.map((c) => c.countryCode);
    },
    countriesByRegion() {
      const result = this.countries.reduce((acc, item) => ({ ...acc, [item.businessRegionName]: [...(acc[item.businessRegionName] || []), item] }), {});
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const region in result) {
        result[region].sort((a, b) => a.name.localeCompare(b.name));
      }
      return result;
    },
    selectAllByRegion() {
      const result = {};
      Object.entries(this.countriesByRegion)
        .forEach(([region, countries]) => {
          result[region] = countries.every((c) => this.selectedCountries[c.countryCode]);
        });
      return result;
    },
    selectAllWorldwide() {
      return this.countries.every((c) => this.selectedCountries[c.countryCode]);
    },
  },
  methods: {
    onChange() {
      const res = Object.entries(this.selectedCountries)
        .filter(([countryCode, allowed]) => allowed && this.validCountryCodes.includes(countryCode))
        .map(([countryCode]) => ({ countryCode }));
      this.$emit('input', res);
    },
    onSelectAll(region) {
      // if region is null, select or deselect all countries
      if (region === null) {
        const state = !this.selectAllWorldwide;
        this.countries
          .forEach((country) => {
            this.selectedCountries[country.countryCode] = state;
          });
        this.selectedCountries = { ...this.selectedCountries };
        return;
      }
      const state = this.selectAllByRegion[region];
      this.countriesByRegion[region]
        .forEach((country) => {
          this.selectedCountries[country.countryCode] = !state;
        });
      this.selectedCountries = { ...this.selectedCountries };
    },
  },
};
