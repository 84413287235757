//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { ValidationObserver, ValidationProvider } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import { trimHexString } from '@/utils/helpers';
import ProductCountrySelector from './ProductCountrySelector.vue';

const animationTypes = {
  vimar_anim: 'Vimar',
  gira_anim: 'Gira',
  legrand_anim: 'Legrand',
};

export default {
  name: 'ProductEditor',
  components: {
    ProductCountrySelector,
    InputField,
    SectionTitle,
    ValidationObserver,
    ValidationProvider,
  },
  props: ['readMode', 'product', 'productProperties',
    'category', 'productFamilies', 'enableImageUpload',
    'eanCodes', 'showAttachments', 'pimcoreImport',
    'disableCategoryEdit', 'countries', 'locale'],
  data() {
    return {
      allowedCountries: [],
      currentProduct: {},
      currentProductProperties: [],
      currentCategory: null,
      images: [],
      selectedIndex: 0,
      categories: [
        { value: 'lights', label: this.$t('generalLights') },
        { value: 'sensors', label: this.$t('generalSensors') },
        { value: 'switches', label: this.$t('generalSwitches') },
      ],
    };
  },
  computed: {
    selectedFamilyName() {
      return this.familyOptions?.find((family) => family?.value === this.currentProduct?.familyMetaId)?.label || '–';
    },
    selectedAnimationType() {
      return this.animationTypes?.find((type) => type?.key === this.currentProduct?.animationType)?.value || '–';
    },
    productExistsInDatabase() {
      return !!(this.pimcoreImport && this.pimcoreImport.catalogProduct);
    },
    observer() {
      return this.$refs.observer;
    },
    isEanCodeExists() {
      return this.currentProduct.ean && this.eanCodes?.includes(this.currentProduct.ean) && this.product?.ean !== this.currentProduct.ean;
    },
    pictures() {
      return this.product?.attachments?.pictures || [];
    },
    pimcoreImportChanges() {
      const arr1 = this.pimcoreImport?.differences?.filter((k) => !['family', 'productProperties'].includes(k.key)) || [];
      const arr2 = this.pimcoreImport?.differences?.find((d) => d.key === 'productProperties')?.changes || [];
      const result = [...arr1, ...arr2].reduce((acc, diff) => ({ ...acc, [diff.key]: diff.new !== diff.old }), {});
      return { ...result };
    },
    familyCompatible() {
      return this.currentCategory === 'lights' || this.currentCategory === 'switches';
    },
    animationTypeCompatible() {
      return this.currentCategory === 'switches' && this.currentProduct.familyMetaId === this.greenPowerFamilyMetaId;
    },
    greenPowerFamilyMetaId() {
      return this.productFamilies.find((f) => f.name === 'green-power')?.id;
    },
    familyOptions() {
      if (!this.familyCompatible) {
        return [];
      }
      return this.productFamilies
        .filter((family) => family.category === this.currentCategory)
        .map((family) => {
          const localisedName = family.localisedNames.find((loc) => loc.code === this.locale) || family.localisedNames.find((loc) => loc.code === 'en');
          return {
            label: localisedName ? localisedName.value : family.name,
            value: family.id,
          };
        });
    },
    animationTypes() {
      return Object.entries(animationTypes).map(([key, value]) => ({ key, value }));
    },
    isNewProduct() {
      return this.$route.name === 'products-catalog';
    },
  },
  watch: {
    product: {
      handler() {
        this.setLocalStates();
      },
      deep: true,
      immediate: true,
    },
    productProperties: {
      handler() {
        this.setLocalStates();
      },
      deep: true,
      immediate: true,
    },
    currentCategory() {
      if (!this.isNewProduct) {
        return;
      }
      this.currentProduct.family = null;
      this.currentProduct.familyMeta = null;
      this.currentProduct.familyMetaId = null;
      this.currentProductProperties = this.productProperties.reduce((arr, p) => {
        let val = Object.values(this.currentProduct.productProperties || {})
          .filter((prod) => prod.key === p.key);
        val = (val[0] && val[0].value) || '';
        arr.push({
          ...p,
          value: val,
        });
        return arr;
      }, []);
    },
  },
  methods: {
    async validate() {
      const isValid = await this.observer?.validate();
      return isValid && !this.isEanCodeExists;
    },
    async setLocalStates() {
      if (!this.currentCategory) {
        this.currentCategory = this.category;
      }
      const product = { ...(this.product || {}) };
      this.currentProduct = {
        ...product,
        ean: product.ean || null,
        family: product.familyMeta?.name || product.family || '',
        familyMeta: product.familyMeta || null,
        familyMetaId: product.familyMeta?.id || null,
      };
      this.currentProductProperties = this.productProperties.reduce((arr, p) => {
        let val = Object.values(this.currentProduct.productProperties || {})
          .filter((prod) => prod.key === p.key);
        val = (val[0] && val[0].value) || '';
        arr.push({ ...p, value: val });
        return arr;
      }, []);
    },
    onCategoryChanged({ value }) {
      this.currentCategory = value;
    },
    getProduct() {
      const filteredProperties = (this.currentProductProperties)
        .filter((value) => value.category === this.currentCategory)
        .map((prodProp) => ({ key: prodProp.key, value: prodProp.value }));

      // todo: temporary fixed numberOfChannels value
      if (this.currentProduct.category === 'switches') {
        const numberOfCh = filteredProperties.find((p) => p.key === 'numberOfChannels');
        if (numberOfCh) {
          numberOfCh.value = 4;
        } else {
          filteredProperties.push({ key: 'numberOfChannels', value: 4 });
        }
      }

      return {
        name: this.currentProduct.name,
        vendor: this.currentProduct.vendor,
        category: this.currentCategory,
        modelStringIdentifier: this.currentProduct.modelStringIdentifier,
        manufacturer: trimHexString(this.currentProduct.manufacturer),
        model: trimHexString(this.currentProduct.model),
        ean: this.currentProduct.ean,
        family: this.currentProduct.family || undefined,
        productProperties: filteredProperties,
        productCountries: this.allowedCountries,
        familyMetaId: this.currentProduct.familyMetaId,
        animationType: this.currentProduct.animationType,
      };
    },
    getImages() {
      return this.images;
    },
    onImageClick() {

    },
  },
};

