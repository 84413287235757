//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['value', 'title', 'image', 'message', 'buttons', 'hideButtons', 'disableClose'],
  methods: {
    onMainClick() {
      (this.buttons.main.click || (() => {}))();
    },
    onSecondaryClick() {
      (this.buttons.secondary.click || (() => {}))();
    },
  },
};
