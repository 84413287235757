//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// TODO: Rewrite this component
import { mapActions, mapGetters } from 'vuex';
import AdvancedTable from '../../components/ui/AdvancedTable.vue';

export default {
  name: 'FamilyMeta',
  components: { AdvancedTable },
  data() {
    return {
      selectedFiles: {},
    };
  },
  created() {
    this.listFamiliesMeta();
  },
  computed: {
    ...mapGetters('products', ['getFamiliesMeta']),
    families() {
      return this.getFamiliesMeta.map((family) => {
        const localisedName = family.localisedNames?.find((loc) => loc.code === this.locale) || family.localisedNames?.find((loc) => loc.code === 'en');
        return {
          ...family,
          name: localisedName ? localisedName.value : family.name,
        };
      }).sort((a, b) => a.name.localeCompare(b.name));
    },
    familyTableHeaders() {
      return [
        {
          key: 'name',
          type: 'text',
          sortable: true,
          searchable: true,
        },
        {
          key: 'image',
          type: 'text',
          sortable: false,
          searchable: false,
        },
        {
          key: 'upload',
          type: 'text',
          sortable: false,
          searchable: false,
        },
        {
          key: 'save',
          type: 'text',
          sortable: false,
          searchable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions('products', ['listFamiliesMeta', 'uploadFamilyMetaImage']),
    async saveFamilyMetaImage(familyId) {
      if (this.selectedFiles[familyId]) {
        await this.uploadFamilyMetaImage({ familyMetaId: familyId, image: this.selectedFiles[familyId] });
        await this.listFamiliesMeta();
        this.selectedFiles[familyId] = null;
      }
    },
  },
};

