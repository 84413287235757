var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('h1',{staticClass:"mb-5 text-center text-uppercase"},[_vm._v("Reset your password")]),_c('p',{staticClass:"mb-5"},[_vm._v("To update your password please enter your e-mail address, the code you've received and your new password")]),(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Could not reset your password. Please check the data you entered is correct! ")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"name":"email","placeholder":"email address","autocomplete":"off","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-5"},[_c('b-form-input',{attrs:{"name":"token","placeholder":"token","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, min: 8, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({
                customClass: 'auth-tooltip',
                title: 'The password must be between 8 and 30 characters long and must contain at least one uppercase letter, one lowercase letter and one number'
              }),expression:"{\n                customClass: 'auth-tooltip',\n                title: 'The password must be between 8 and 30 characters long and must contain at least one uppercase letter, one lowercase letter and one number'\n              }",modifiers:{"left":true}}],staticClass:"mb-5"},[_c('b-form-input',{attrs:{"type":"password","placeholder":"new password","autocomplete":"new-password","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"text-center mb-1"},[_c('b-button',{attrs:{"disabled":!valid||_vm.submitted,"type":"submit","pill":"","variant":"primary"}},[_vm._v("Save password")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }