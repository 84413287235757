//
//
//
//
//
//

export default {
  name: 'Home',
  data() {
    return {};
  },
};
