//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ConfirmationModal from '@/components/ui/ConfirmationModal.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import AdminEditor from './AdminEditor.vue';

export default {
  name: 'AdminModal',
  props: ['admin'],
  components: {
    AdminEditor,
    ConfirmationModal,
  },
  data() {
    return {
      loading: false,
      visible: false,
    };
  },
  computed: {
    ...mapState('admins', ['admins']),
    ...mapGetters('contents', ['getCountryList']),
    ...mapState('auth', ['userId']),
    authedAdmin() {
      return { uuid: this.userId };
    },
    countries() {
      return this.getCountryList;
    },
    editMode() {
      return !!this.admin;
    },
  },
  methods: {
    ...mapActions('contents', ['getCountries']),
    ...mapActions('admins', ['getAdmins', 'createAdmin', 'updateAdmin']),
    show() {
      this.visible = true;
    },
    onCancel() {
      this.visible = false;
    },
    async onSubmit() {
      const isValid = await this.$refs.adminEditor?.validate();
      if (!isValid) {
        return;
      }
      this.$bvModal.show('confirmation-modal');
    },
    async onSaveAdmin() {
      this.$bvModal.hide('confirmation-modal');
      this.loading = true;
      const admin = this.$refs.adminEditor.getAdmin();
      try {
        if (this.editMode) {
          await this.updateAdmin({ uuid: this.admin.uuid, data: admin });
        } else {
          await this.createAdmin({ data: admin });
        }
      } catch (err) {
        this.loading = false;
        throw err;
      }

      await this.getAdmins();
      this.loading = false;
      this.visible = false;
    },
  },
};

