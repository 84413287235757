//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

export default {
  name: 'NavLangDropdown',
  computed: {
    ...mapState('app', ['locale']),
  },
  methods: {
    ...mapActions('app', ['changeLocale']),
    async onChangeLocale(locale) {
      await this.changeLocale(locale);
    },
  },
};
