//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['hideFilter', 'fields', 'savedSort', 'dark', 'light', 'value', 'initialFire', 'disabled', 'defaultSelectedIndex'],
  data() {
    return {
      searchText: '',
      sort: '',
    };
  },
  created() {
    if (this.value && this.value.searchText) {
      this.searchText = this.value.searchText;
    }
    if (this.initialFire) {
      if (this.savedSort) {
        this.sort = this.savedSort.sort;
        this.searchText = this.savedSort.searchText;
      }
      this.onFilterChange();
      return;
    }
    if (this.defaultSelectedIndex && this.defaultSelectedIndex > 0) {
      this.selectSort(this.sortFields[this.defaultSelectedIndex]);
    }
  },
  computed: {
    selectedItem() {
      return this.sortFields.find((i) => i.selected) || this.sortFields[0] || {};
    },
    sortFields() {
      return this.fields.map((f, index) => ({ ...f, selected: (index === 0 && !this.sort) || f.sort === this.sort }));
    },
  },
  methods: {
    onFilterChange() {
      this.$emit('filterChange', {
        searchText: this.searchText,
        sort: this.selectedItem.sort,
        sortType: this.selectedItem.sortType,
      });
    },
    selectSort(item) {
      this.sort = item.sort;
      this.onFilterChange();
    },
  },
};
