//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['title', 'iconClass', 'disabled', 'large', 'iconOnly', 'marginLeft'],
  data() {
    return {};
  },
};
