var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmReg)}}},[_c('h1',{staticClass:"mb-5 text-center text-uppercase"},[_vm._v("Confirmation")]),_c('p',{staticClass:"mb-5"},[_vm._v("To confirm your registration please enter your e-mail address and the code you've received")]),(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("E-mail or token incorrect!")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"type":"email","placeholder":"email address","autocomplete":"off","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-5"},[_c('b-form-input',{attrs:{"placeholder":"token","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"text-center mb-1"},[_c('b-button',{attrs:{"disabled":!valid||_vm.submitted,"type":"submit","pill":"","variant":"primary"}},[_vm._v("Confirm")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }