//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import AccessProjectModal from '@/components/ui/AccessProjectModal.vue';
import QRAccessProjectModal from '@/components/ui/QRAccessProjectModal.vue';
import { formatDate } from '@/utils/helpers';
import { ValidationObserver } from 'vee-validate';
import AdvancedTable from '../../components/ui/AdvancedTable.vue';
import InputField from '../../components/ui/InputField.vue';

export default {
  name: 'ProjectsList',
  components: {
    ValidationObserver,
    InputField,
    AdvancedTable,
    AccessProjectModal,
    QRAccessProjectModal,
  },
  data() {
    return {
      selectedProject: {},
      selectedUuid: '',
      selectedUnlockConfirm: false,
      prevRoute: null,
      loading: false,
      headers: [{
        title: 'Project name', key: 'name', type: 'text', searchable: true,
      }, {
        title: 'Address', key: 'address', type: 'text', searchable: true,
      }, {
        title: 'Available until', key: 'expiresAt', type: 'text', searchable: true,
      }, {
        title: 'Status', key: 'configEditAllowed', type: 'text', searchable: true,
      }, {
        title: '', key: 'actions', type: 'text', searchable: false,
      }],
      grantName: '',
      grantEmail: '',
    };
  },
  watch: {
    async getAccessCodeStatus(value) {
      if (value === 'success') {
        await this.listProjects();
        this.$bvModal.hide('access-project-modal');
      }
    },
  },
  async created() {
    await this.listProjects();
  },
  computed: {
    ...mapGetters('projects', ['filteredProjectList', 'projectsCount', 'getAccessCodeStatus']),
    projects() {
      return this.filteredProjectList;
    },
    observer() {
      return this.$refs.observer;
    },
    projectList() {
      return this.projects;
    },
    accessCodeLoading() {
      return this.getAccessCodeStatus === 'loading';
    },
    accessCodeError() {
      return this.getAccessCodeStatus === 'error';
    },
    items() {
      return this.projects.map((project) => ({
        ...project,
        uuid: project.uuid,
        name: project.name,
        address: project.address && project.address.formatted ? project.address.formatted : '–',
        expiresAt: project.expiresAt && project.expiresAt.length ? this.getDate(project.expiresAt) : '–',
      }));
    },
  },
  methods: {
    ...mapActions('projects', ['listProjects', 'submitCode', 'forceUnlockProject', 'sendInvite']),
    onGrantAccessModal(project) {
      this.selectedProject = project;
      this.selectedUuid = project.uuid;
      this.$bvModal.show('grant-access-modal');
    },
    async onSendInvite() {
      const isValid = await this.observer?.validate();
      if (!isValid) {
        return;
      }
      this.loading = true;
      try {
        await this.sendInvite({
          name: this.grantName,
          email: this.grantEmail,
          uuid: this.selectedUuid,
        });
        this.$bvModal.hide('grant-access-modal');
        this.$bvModal.show('invite-sent-modal');
      } finally {
        this.loading = false;
      }

      this.selectedProject = '';
      this.selectedUuid = '';
      this.grantEmail = '';
      this.grantName = '';
      this.$bvModal.hide('grant-access-modal');
    },
    async onUnlockProject(uuid) {
      await this.forceUnlockProject(uuid);
      await this.listProjects();
      this.selectedProject = '';
      this.selectedUuid = '';
      this.selectedUnlockConfirm = false;
      this.$bvModal.hide('unlock-project-modal');
    },
    openUnlockModal(project) {
      this.selectedProject = project;
      this.selectedUuid = project.uuid;
      this.selectedUnlockConfirm = false;
      this.$bvModal.show('unlock-project-modal');
    },
    async submitAccessCode(code) {
      await this.submitCode(code);
    },
    getDate(value) {
      return formatDate(value);
    },
  },
};
