var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects"},[_c('section',{staticClass:"container-fluid"},[_c('b-row',{staticClass:"project-details"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t('pageProfileSettings')))])]),_c('b-col',{attrs:{"md":"12"}},[_c('ul',[_c('li',[_c('inline-text-editor',{attrs:{"required":"","placeholder":_vm.$t('authRegistrationName'),"max-length":128,"value":_vm.profile.name},on:{"update":function($event){return _vm.save('name')},"update:value":function($event){return _vm.$set(_vm.profile, "name", $event)}}},[_c('span',{staticClass:"editable",attrs:{"slot":"edit-label"},slot:"edit-label"}),_c('span',{staticClass:"inline-editor-cancel",attrs:{"slot":"cancel-label"},slot:"cancel-label"}),_c('span',{staticClass:"inline-editor-confirm",attrs:{"slot":"confirm-label"},slot:"confirm-label"})])],1),_c('li',{staticClass:"flex-wrap"},[_c('div',{staticClass:"w-75"},[_vm._v(_vm._s(_vm.profile.email))]),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.change-email-modal",modifiers:{"change-email-modal":true}}],staticClass:"flex-shrink-1"},[_vm._v(_vm._s(_vm.$t('profileChangeEmail')))]),(_vm.profile.pendingEmail)?_c('div',{staticClass:"alert alert-info small w-100 mt-3"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('profileChangeEmailConfirmMessage', {email: _vm.profile.pendingEmail}))}}),_c('ValidationObserver',{ref:"tokenobserver",staticClass:"mt-2 d-flex",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{staticClass:"w-25",attrs:{"rules":"required","vid":"token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"token","state":errors[0] ? false : null},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{staticClass:"ml-3",attrs:{"disabled":!valid||_vm.loading,"variant":"primary","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.confirm.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('generalConfirm')))]),_c('div',{staticClass:"col-form-label w-50 text-right"},[_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.cancelChange()}}},[_vm._v(_vm._s(_vm.$t('profileDontChangeEmail')))])],1)]}}],null,false,1432689237)})],1):_vm._e()],1),_c('li',{staticClass:"flex-wrap"},[_c('div',{staticClass:"w-75"},[_vm._v(_vm._s(_vm.profile.phone))]),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.change-phone-modal",modifiers:{"change-phone-modal":true}}],staticClass:"flex-shrink-1"},[_vm._v(_vm._s(_vm.$t('profileChangePhone')))])],1),_c('li',{staticStyle:{"border-bottom":"0"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.change-password-modal",modifiers:{"change-password-modal":true}}],staticClass:"d-block mx-auto mt-3 mb-1",attrs:{"variant":"primary","pill":""}},[_vm._v(_vm._s(_vm.$t('profileChangePassword')))])],1)])])],1)],1),_c('b-modal',{attrs:{"id":"change-email-modal","size":"md","centered":"","hide-footer":""},model:{value:(_vm.changeEmailModal),callback:function ($$v) {_vm.changeEmailModal=$$v},expression:"changeEmailModal"}},[_c('span',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_vm._v(_vm._s(_vm.$t('profileChangeEmail')))]),_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.$t('profileChangeEmailDesc')))]),_c('p',[_vm._v(_vm._s(_vm.$t('profileChangeEmailDescDetails')))]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required|email|max:255","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"type":"email","placeholder":_vm.$t('profileNewEmail'),"autocomplete":"off","state":errors[0] ? false : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"d-block mx-auto mt-5 mb-3",attrs:{"disabled":!valid||_vm.loading,"variant":"primary","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.save('email')}}},[_vm._v(_vm._s(_vm.$t('profileChangeEmail')))]),_c('b-link',{staticClass:"d-block mx-auto text-center",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide('change-email-modal')}}},[_vm._v(_vm._s(_vm.$t('generalCancel')))])]}}])})],1)],1)],1),_c('b-modal',{attrs:{"id":"change-password-modal","size":"md","centered":"","hide-footer":""},model:{value:(_vm.changePasswordModal),callback:function ($$v) {_vm.changePasswordModal=$$v},expression:"changePasswordModal"}},[_c('span',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_vm._v(_vm._s(_vm.$t('profileChangePassword')))]),(!_vm.changePasswordSuccess)?_c('b-row',[_c('b-col',[_c('ValidationObserver',{ref:"passwordobserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required|password|min:8|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('authOldPassword')}},[_c('b-form-input',{attrs:{"type":"password","autocomplete":"current-password","state":errors[0] ? false : null},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password|min:8|max:32","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('authNewPassword')}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({ title: _vm.$t('generalPasswordTooltip') }),expression:"{ title: $t('generalPasswordTooltip') }",modifiers:{"left":true}}],attrs:{"type":"password","autocomplete":"new-password","state":errors[0] ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('authNewRepeatPassword')}},[_c('b-form-input',{attrs:{"type":"password","autocomplete":"new-password","state":errors[0] ? false : null},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"d-block mx-auto mt-5 mb-3",attrs:{"disabled":!valid||_vm.loading,"variant":"primary","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('profileChangePassword')))]),_c('b-link',{staticClass:"d-block mx-auto text-center",on:{"click":function($event){$event.preventDefault();return _vm.hidePasswordModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('generalCancel')))])]}}],null,false,3921969440)})],1)],1):_vm._e(),(_vm.changePasswordSuccess)?_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.$t('profileChangePasswordSuccess')))]),_c('b-button',{staticClass:"d-block mx-auto mt-5 mb-3",attrs:{"variant":"primary","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.hidePasswordModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('generalClose')))])],1)],1):_vm._e()],1),_c('b-modal',{attrs:{"id":"change-phone-modal","size":"md","centered":"","hide-footer":""},model:{value:(_vm.changePhoneModal),callback:function ($$v) {_vm.changePhoneModal=$$v},expression:"changePhoneModal"}},[_c('span',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_vm._v(_vm._s(_vm.$t('profileChangePhone')))]),_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.$t('profileChangePhoneDesc')))]),_c('ValidationObserver',{ref:"phoneobserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required|phone|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({ title: _vm.$t('generalPhoneNumberTooltip')}),expression:"{ title: $t('generalPhoneNumberTooltip')}",modifiers:{"left":true}}],attrs:{"placeholder":_vm.$t('authMobilePhone'),"state":errors[0] ? false : null},on:{"keyup":_vm.formatPhoneNumber},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"d-block mx-auto mt-5 mb-3",attrs:{"disabled":!valid||_vm.loading,"variant":"primary","pill":""},on:{"click":function($event){$event.preventDefault();return _vm.save('phone')}}},[_vm._v(_vm._s(_vm.$t('profileChangePhone')))]),_c('b-link',{staticClass:"d-block mx-auto text-center",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide('change-phone-modal')}}},[_vm._v(_vm._s(_vm.$t('generalCancel')))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }