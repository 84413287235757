//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from 'vuex';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import ProductModal from '@/components/ui/products/ProductModal.vue';
import PimcoreModal from '@/components/ui/products/PimcoreModal.vue';

export default {
  name: 'Catalog',
  components: {
    ProductModal,
    FixedFooter,
    AdvancedTable,
    PimcoreModal,
  },
  data() {
    return {
      categories: ['lights', 'switches', 'sensors', 'all'],
      categoryTranslate: {
        lights: 'generalLights',
        sensors: 'generalSensors',
        switches: 'generalSwitches',
        all: 'generalAll',
      },
      headers: [{
        title: this.$t('product.name'), key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('product.ean'), key: 'ean', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('product.vendor'), key: 'vendor', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('productModel'), key: 'model', type: 'text', sortable: false, searchable: false,
      }, {
        title: this.$t('product.origin'), key: 'source', type: 'text', sortable: true, searchable: false,
      }],
      loading: false,
      searchLength: 1,
      rerenderKey: 1,
    };
  },
  async created() {
    if (!this.products.length || !this.getProductProperties.length) {
      this.loading = true;
    }
    await this.getCountries();
    await this.listProducts();
    await this.listFamiliesMeta();
    await this.getProperties();
    this.loading = false;
  },
  computed: {
    ...mapState('products', ['radioFilters', 'savedSort']),
    ...mapState('contents', ['countries']),
    ...mapGetters('products', ['getProductsByCategory', 'getProductProperties', 'getAllProducts', 'isEanCodeExists']),
    regionCountryList() {
      const countries = [...this.countries];
      const result = [{ name: this.$t('generalSelectAll'), value: 'ALL' }];
      countries.sort((a, b) => a.businessRegionName.localeCompare(b.businessRegionName) || a.name.localeCompare(b.name));
      countries.reduce((acc, country) => {
        if (country.businessRegionName !== acc) {
          result.push({ name: country.businessRegionName, type: 'businessRegion' });
        }
        result.push({ ...country, type: 'country' });
        return country.businessRegionName;
      }, '');
      return result;
    },
    firmwareFilters() {
      return this.radioFilters;
    },
    selectedCategory() {
      return this.radioFilters.selected === 'all' ? 'lights' : this.radioFilters.selected;
    },
    products() {
      const products = this.getProductsByCategory(this.radioFilters.selected)
        .map((product) => ({ ...product, to: { name: 'products-details', params: { productId: product.uuid } } }));
      return products.filter((product) => {
        if (!this.radioFilters.selectedRegionCountry || this.radioFilters.selectedRegionCountry.value === 'ALL') {
          return true;
        }

        if (this.radioFilters.selectedRegionCountry.type === 'country') {
          return (product.countries || []).find((c) => c.name === this.radioFilters.selectedRegionCountry.name);
        }

        if (this.radioFilters.selectedRegionCountry.type === 'businessRegion') {
          return (product.countries || []).find((c) => c.businessRegionName === this.radioFilters.selectedRegionCountry.name);
        }
        return false;
      });
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('productNewProduct'),
          class: this.loading ? 'disabled' : '',
          click: () => this.openAddNewProductModal(),
        },
        tertiary: {
          title: this.$t('productImportFromPimcore'),
          class: this.loading ? 'disabled' : '',
          click: () => this.openPimcoreModal(),
        },
      };
    },
    computedSavedSort() {
      return this.savedSort;
    },
  },
  methods: {
    ...mapActions('contents', ['getCountries']),
    ...mapActions('products', ['listProducts', 'getProperties', 'radioFiltersUpdate', 'listFamiliesMeta', 'savedSortUpdate']),
    onRadioFilterChange() {
      this.radioFiltersUpdate(this.radioFilters);
      this.resetSavedSort();
    },
    onFilterChange(sort) {
      this.savedSortUpdate(sort);
    },
    onRegionCountrySelectInput() {
      this.resetSavedSort();
    },
    openItem(item) {
      this.$router.push({ name: 'products-details', params: { productId: item.uuid } });
    },
    openAddNewProductModal() {
      if (this.loading) {
        return;
      }
      this.$bvModal.show('new-product-modal');
    },
    getSearchListLength(length) {
      this.searchLength = length;
    },
    openPimcoreModal() {
      this.$bvModal.show('pimcore-modal');
    },
    resetSavedSort() {
      this.savedSortUpdate({ sort: 'name##asc', sortType: 'text', searchText: '' });
      // eslint-disable-next-line no-plusplus
      this.rerenderKey++;
    },
  },
};
